import axios from "axios";
import store from "../store";

const api = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(function(config) {
  // Do something before request is sent
  let authKey = store.state.auth.token;
  config.headers["Authorization"] = "bearer " + authKey;
  return config;
});

export default api;
